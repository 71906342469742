import {
  RefreshUserTokenPlugin,
  ShowUserVerificationDialogPlugin,
} from '@/features/core/auth/plugins';
import type { Order, OrderPluginDto } from '@/features/orders';
import {
  AppCrashPlugin,
  BooleanSwitcherPlugin,
  LogStartOrderEventsPlugin,
  NavigateToTargetRoutePlugin,
  OrderItemStatus,
  OrderLocalStatus,
  ResetOrderQuantityPlugin,
  SaveOrderAndSyncDirect,
  SaveOrderAndSyncScheduledPlugin,
  SetEventsToPatchPlugin,
  SetItemStatusPlugin,
  SetLastOrderStatusChangedPlugin,
  SetOrderStatusPlugin,
  SetTargetRoutePlugin,
  TrackOrderEventsPlugin,
} from '@/features/orders';
import type { StartPickingSwitchers } from '@/features/orders/types';
import type { PluginPipeline } from '@ads/plugin-pipeline';
import { EmitEventBusStatusChangePlugin } from '../plugins/emit-event-bus-status-change';
import { UpdateDeviceInfoPlugin } from '@/features/device-info';

const isPickingInProgressOrReady = (dto: OrderPluginDto) =>
  dto.order.localStatus === OrderLocalStatus.PickingInProgress ||
  dto.order.localStatus === OrderLocalStatus.PickingReady;

export function startPickingRegisterPlugin(
  order: Order,
  switchers: StartPickingSwitchers,
  startOrderPlugin: PluginPipeline<OrderPluginDto>,
): PluginPipeline<OrderPluginDto> {
  startOrderPlugin.registerPlugins([
    new BooleanSwitcherPlugin(switchers.isProcessing, true),
  ]);

  startOrderPlugin.registerPlugins(
    [
      new TrackOrderEventsPlugin(),
      new EmitEventBusStatusChangePlugin('picking-continued'),
    ],
    (dto: OrderPluginDto) => {
      return (
        dto.order.localStatus === OrderLocalStatus.Paused ||
        dto.order.localStatus === OrderLocalStatus.PickingInProgress
      );
    },
  );

  startOrderPlugin.registerPlugins([new LogStartOrderEventsPlugin()]);

  startOrderPlugin.registerPlugins(
    [
      new ResetOrderQuantityPlugin(),
      new SetItemStatusPlugin(OrderItemStatus.not_picked),
      new SaveOrderAndSyncScheduledPlugin(),
    ],
    (dto: OrderPluginDto) => {
      return dto.order.localStatus === OrderLocalStatus.PickingInProgress;
    },
  );

  startOrderPlugin.registerPlugins(
    [
      new EmitEventBusStatusChangePlugin('picking-started'),
      new SetItemStatusPlugin(OrderItemStatus.not_picked),
      new SetLastOrderStatusChangedPlugin(),
      new TrackOrderEventsPlugin(),
      new BooleanSwitcherPlugin(switchers.isUserTokenRefreshing, true),
      new RefreshUserTokenPlugin<OrderPluginDto>(),
      new BooleanSwitcherPlugin(switchers.isUserTokenRefreshing, false),
      new ShowUserVerificationDialogPlugin(),
      new SaveOrderAndSyncDirect(),
      new SetOrderStatusPlugin(OrderLocalStatus.PickingInProgress),
      new SetEventsToPatchPlugin(),
      new SaveOrderAndSyncScheduledPlugin(),
      new UpdateDeviceInfoPlugin(true),
    ],
    (dto: OrderPluginDto) => {
      return dto.order.localStatus === OrderLocalStatus.PickingReady;
    },
  );

  startOrderPlugin.registerPlugins(
    [new AppCrashPlugin()],
    isPickingInProgressOrReady,
  );

  startOrderPlugin.registerPlugins([
    new SetTargetRoutePlugin({
      name: 'picking-order',
      params: {
        id: order.id,
      },
    }),
  ]);

  startOrderPlugin.registerPlugins([
    new LogStartOrderEventsPlugin(),
    new BooleanSwitcherPlugin(switchers.isProcessing, false),
    new NavigateToTargetRoutePlugin(),
  ]);

  return startOrderPlugin;
}
